import 'index.css'
import 'syntax-highlighting.css'
import Turbolinks from 'turbolinks'
import 'turbolinks-animate'
import * as THREE from 'three'
import p5 from 'p5'
import TRUNK from 'vanta/dist/vanta.trunk.min'

Turbolinks.start()

let prevBodyClasses = ''
let nextBodyClasses = ''

document.addEventListener('turbolinks:before-render', function (event) {
  const nextBody = event.data.newBody
  prevBodyClasses = document.querySelector('body').className
  nextBodyClasses = nextBody.className

  // Change the body classes for the next body, to the previous ones
  nextBody.setAttribute('class', prevBodyClasses)
})

// Animate between page transitions
document.addEventListener('turbolinks:load', function () {
  // Now change the body classes, to the actual new ones
  if (nextBodyClasses !== '' && nextBodyClasses !== prevBodyClasses) {
    setTimeout(function () {
      document.querySelector('body').setAttribute('class', nextBodyClasses)
    }, 10)
  }

  // .. and animate the body content
  TurbolinksAnimate.init({
    element: document.querySelector('body'),
    animation: 'fadeIn',
    duration: '0.8s',
    delay: 300,
    // reversedDisappearing: true,
  })
})

// Import all JavaScript & CSS files from src/_components
// import components from 'bridgetownComponents/**/*.{js,jsx,js.rb,css}'

// Hamburger menu
document.addEventListener('turbolinks:load', function () {
  const hamburgerButton = document.querySelector('#hamburger-button')
  const hamburgerMenu = document.querySelector('.hamburger-links')

  hamburgerButton.onclick = function () {
    hamburgerMenu.classList.toggle('hidden')
    // Hide .content-and-footer when menu is open
    document.querySelector('.content-and-footer').classList.toggle('hidden')
  }

  // The icon
  document.querySelector('#hamburger-icon').onclick = function () {
    this.classList.toggle('open')
  }
})

let trunkAnimation = null // variable to store the animation instance

// Vanta.js animation for the homepage
document.addEventListener('turbolinks:load', function () {
  // Destroy the previous instance if it exists
  if (trunkAnimation) {
    trunkAnimation.destroy()
    trunkAnimation = null
  }

  // Initialize new instance if on the homepage
  if (document.querySelector('#trunk')) {
    trunkAnimation = TRUNK({
      el: '#trunk',
      THREE: THREE,
      p5: p5,
      mouseControls: false,
      touchControls: false,
      gyroControls: false,
      minHeight: 200.0,
      minWidth: 200.0,
      scale: 1.0,
      scaleMobile: 1.0,
      color: 0x984593,
      backgroundColor: 'transparent',
      chaos: 1.3,
    })
  }
})
